// import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Notify, Button, Sweetalert, Tree, BlockLoading } from 'zent';
import { injectIntl } from 'react-intl';
import { withBaseIcon } from 'react-icons-kit';
import { edit } from 'react-icons-kit/feather/edit';
import { trash2 } from 'react-icons-kit/feather/trash2';

import { fetchCategories, deleteCategory } from '../../services/categoryService';
import Block from '../../components/common/block';
import Modal from '../../components/category/modal';

const Icon = withBaseIcon({ size: 20, style: { color: '#444' } });

const Users = ({ intl }) => {
  const [items, setItems] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [modal, setModal] = useState({
    status: false,
    selectedItem: null,
  });
  const { messages } = intl;

  useEffect(() => {
    return fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (query = '') => {
    fetchCategories(query)
      .then((res) => {
        setItems(res.data);
        setLoading(false);
      })
      .catch((err) => Notify.error(messages['error.network']));
  };

  const removeCategory = ({ id }) => {
    Sweetalert.confirm({
      content: `${messages['alert.removeText']}`,
      title: `${messages['alert.title']}`,
      confirmType: 'danger',
      confirmText: `${messages['alert.success']}`,
      cancelText: `${messages['alert.dismiss']}`,
      onConfirm: () =>
        new Promise((resolve) => {
          deleteCategory(id)
            .then(() => {
              fetchData();
              Notify.success(messages['user.removeSuccess'], 5000);
              return resolve();
            })
            .catch((err) => {
              Notify.error(messages['error.network']);
              return resolve();
            });
        }),
    });
  };

  const editCategory = (selectedItem) =>
    setModal({
      status: true,
      selectedItem,
    });

  const onUpdate = () => {
    fetchData();
    return setModal({ selectedItem: null, status: false });
  };

  const operations = [
    {
      name: 'ویرایش',
      icon: <Icon icon={edit} />,
      action: editCategory,
    },
    {
      name: 'حذف',
      icon: <Icon icon={trash2} />,
      action: removeCategory,
    },
  ];

  return (
    <div className='animated fadeIn'>
      <BlockLoading loading={isLoading}>
        <Block>
          <h1 className='title'>{messages['category.title']}</h1>
          <div className='row'>
            <Button className='add-btn' onClick={() => setModal({ status: true, selectedItem: null })}>
              {messages['category.add']}
            </Button>
          </div>
        </Block>
        <Tree data={items} size='large' operations={operations} />
      </BlockLoading>
      {modal.status && <Modal modal={modal} onUpdate={onUpdate} onClose={() => setModal({ ...modal, status: false })} />}
    </div>
  );
};

export default injectIntl(Users);
