import React, { Component } from 'react';
import styled from 'styled-components';

export default class errorBoundaries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: info,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Column>
            <h1>Error</h1>
            <h3>Something went wrong</h3>
          </Column>
          <Column />
        </Container>
      );
    }
    return this.props.children;
  }
}

const Container = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  height: 100vh;
  position: relative;
`;

const Column = styled.div`
  margin: 0 10px;
  h1 {
    color: #4944ce;
    font-size: 5.5em;
    margin: 0;
  }
  h3 {
    color: #696969;
    font-size: 1.3em;
    margin-bottom: 10px;
  }
  p {
    color: #818181;
    font-size: 14px;
  }
`;
