import React, { createContext, useContext, useReducer } from 'react';
import { IntlProvider } from 'react-intl';

import messages_fa from '../translations/fa.json';
import messages_en from '../translations/en.json';

const messages = {
  'fa-IR': messages_fa,
  'en-US': messages_en,
};

const languages = [
  { id: 0, name: 'فارسی', code: 'fa-IR', direction: 'rtl' },
  { id: 1, name: 'en', code: 'en-US', direction: 'ltr' },
];

// Fetch default language
const fetchDefaultLanguage = () => {
  if (localStorage.getItem('@defaultLanguage')) {
    let selectedLanguage = languages.find((item) => item.code === localStorage.getItem('@defaultLanguage'));
    return { ...selectedLanguage, value: selectedLanguage.id };
  }
  return languages[0];
};

// Set language attributes when change language
const changePanelLanguage = (selected) => {
  const element = document.getElementById('changeLanguageOverLay');
  if (element) {
    element.classList.add('active');
    setTimeout(() => {
      localStorage.setItem('@defaultLanguage', selected.code);
      document.documentElement.setAttribute('lang', selected.name);
      document.dir = selected.direction;
    }, 300);
  } else {
    localStorage.setItem('@defaultLanguage', selected.code);
    document.documentElement.setAttribute('lang', selected.name);
    document.dir = selected.direction;
  }
};
// Set default direction or languages
changePanelLanguage(fetchDefaultLanguage());

export const StateContext = createContext({});

export const StateProvider = ({ children }) => {
  // intial state
  let initialState = {
    languages,
    selectedLanguage: fetchDefaultLanguage(),
    profile: null,
  };
  // reducer
  let reducer = (state, action) => {
    switch (action.type) {
      case 'updateProfile':
        return {
          ...state,
          profile: action.profile,
        };
      case 'changeLanguage':
        changePanelLanguage(action.newLanguage);
        return {
          ...state,
          profile: action.profile,
        };
      default:
        return { ...initialState };
    }
  };

  return (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      <IntlProvider locale={initialState.selectedLanguage.code} messages={messages[initialState.selectedLanguage.code]}>
        {children}
      </IntlProvider>
    </StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext);
