import React, { useState, useEffect } from 'react';
import { Grid, Notify, Button, Input, Sweetalert, Pop } from 'zent';
import { injectIntl } from 'react-intl';
import { withBaseIcon } from 'react-icons-kit';
import { edit } from 'react-icons-kit/feather/edit';
import { trash2 } from 'react-icons-kit/feather/trash2';

import { fetchProducts, fetchProductsCount, deleteProduct } from '../../services/productService';
import Block from '../../components/common/block';

const Icon = withBaseIcon({ size: 20, style: { color: '#555' } });

const Products = ({ intl, history }) => {
  const [datasets, setDatasets] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState({
    pageSize: 10,
    total: 0,
    current: 0,
    start: 0,
  });
  const [searchText, setSearchText] = useState('');
  const { messages, locale } = intl;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (query = '', page = 0, start = 0, range = null) => {
    Promise.all([fetchProducts(query, start, range), fetchProductsCount(query, start, range)])
      .then((res) => {
        setDatasets(res[0].data);
        setPageInfo({ ...pageInfo, total: res[1].data, current: page });
        setLoading(false);
      })
      .catch((err) => Notify.error(messages['error.network']));
  };

  const onChange = ({ current }) => {
    setLoading(false);
    fetchData(searchText, Number(current), (current - 2) * 10 + 10);
  };

  const removeProduct = (id) => {
    Sweetalert.confirm({
      content: `${messages['alert.removeText']}`,
      title: `${messages['alert.title']}`,
      confirmType: 'danger',
      confirmText: `${messages['alert.success']}`,
      cancelText: `${messages['alert.dismiss']}`,
      onConfirm: () =>
        new Promise((resolve) => {
          deleteProduct(id)
            .then(() => {
              fetchData(searchText, pageInfo.current, pageInfo.start);
              Notify.success(messages['user.removeSuccess'], 5000);
              return resolve();
            })
            .catch((err) => {
              Notify.error(messages['error.network']);
              return resolve();
            });
        }),
    });
  };

  const onChangeSearch = (e) => {
    let value = e.target.value;
    if (value && value.trim() !== '') {
      setLoading(true);
      return fetchData(value, 0, 0);
    } else if (value.trim() === '') {
      setLoading(true);
      return fetchData('', 0, 0);
    }
  };

  const onPressEnter = (e) => {
    let value = e.target.value;
    if (value && value.trim() !== '') {
      setSearchText(value);
      setLoading(true);
      return fetchData(value, 0, 0);
    }
  };

  const columns = [
    {
      title: messages['label.title'],
      name: 'title',
    },
    {
      title: messages['label.description'],
      name: 'description',
      width: '30%',
      nowrap: true,
    },
    {
      title: messages['label.ingredient'],
      name: 'ingredient',
      width: '20%',
      nowrap: true,
    },
    {
      title: messages['label.category'],
      bodyRender: (data) => {
        return data.categories ? data.categories.title : '----';
      },
    },
    {
      title: '',
      bodyRender: (data) => {
        return (
          <div className='table-control__container'>
            <Button type='primary' className='icon' onClick={() => history.push(`/product/${data.id}`)}>
              <Icon style={{ marginLeft: 5 }} icon={edit} />
            </Button>
            <Button type='danger' className='icon' onClick={() => removeProduct(data.id)}>
              <Icon style={{ marginLeft: 5 }} icon={trash2} />
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <div className='animated fadeIn'>
      <Block>
        <h1 className='title'>
          {messages['product.title']} ({pageInfo.total})
        </h1>
        <div className='row'>
          <Input onPressEnter={onPressEnter} onChange={onChangeSearch} icon='search' placeholder={`${messages['search.placeholder']}`} />
          <Button className='add-btn' onClick={() => history.push('/product/add')}>
            {messages['product.add']}
          </Button>
        </div>
      </Block>
      <Grid pageInfo={pageInfo} columns={columns} datasets={datasets} onChange={onChange} emptyLabel={`${messages['product.empty']}`} loading={isLoading} ellipsis />
    </div>
  );
};

export default injectIntl(Products);
