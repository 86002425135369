/* eslint-disable react-hooks/exhaustive-deps */
// import * as _ from "lodash";
import React from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import withClearCache from './ClearCache';

import { BlockLoading } from 'zent';
// history
import { history } from './utils/history';
// State Provider
import { StateProvider } from './context/state';
// 404
import NotFoundPage from './pages/notfound';
// Navbar
import Navbar from './components/common/navbar';
// Menu
import Menu from './components/common/menu';
// Wrapper
import Wrapper from './components/common/wrapper';
// Auth
import Login from './pages/auth/login';
import ForgotPassword from './pages/auth/forgotPassword';
// Users
import Users from './pages/users/users';
import AddUser from './pages/users/add';
import EditUser from './pages/users/edit';
// Products
import Products from './pages/products/products';
import AddProduct from './pages/products/add';
import EditProduct from './pages/products/edit';
// Categories
import Categories from './pages/categories/categories';
// Transactions
import Transactions from './pages/transactions';

const isAuthenticated = () => {
  return localStorage.getItem('@token');
};

const PrivateRoute = ({ component: Component, title, ...rest }) => {
  document.title = title;
  return (
    <MainContainer className='animated fadeIn'>
      <Navbar />
      <Menu id='menu' />
      <Wrapper>
        <Route
          {...rest}
          render={(props) => {
            return isAuthenticated() ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                }}
              />
            );
          }}
        />
      </Wrapper>
      <div id='changeLanguageOverLay'>
        <BlockLoading loading={true} />
      </div>
    </MainContainer>
  );
};

const PublicRoute = ({ component: Component, title, ...rest }) => {
  document.title = title;
  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

const NoMatch = () => <NotFoundPage />;

function App() {
  return (
    <StateProvider>
      <Router history={history}>
        <Switch>
          {/* Users */}
          <PrivateRoute exact path='/' title={'کاربران'} component={Users} />
          <PrivateRoute exact path='/user/add' title={'درج کاربر'} component={AddUser} />
          <PrivateRoute exact path='/user/:id' title={'ویرایش کاربر'} component={EditUser} />
          {/* Products */}
          <PrivateRoute exact path='/products' title={'محصولات'} component={Products} />
          <PrivateRoute exact path='/product/add' title={'درج محصول'} component={AddProduct} />
          <PrivateRoute exact path='/product/:id' title={'ویرایش محصول'} component={EditProduct} />
          {/* Categories */}
          <PrivateRoute exact path='/categories' title={'دسته بندی‌ها'} component={Categories} />
          {/* Transactions */}
          <PrivateRoute exact path='/transactions' title={'تراکنش‌ها'} component={Transactions} />
          {/* Authentication */}
          <PublicRoute exact path='/login' title={'ورود کاربران'} component={Login} />
          <PublicRoute exact path='/forgetpassword' title={'فراموشی رمز عبور'} component={ForgotPassword} />
          {/* 404 */}
          <PublicRoute component={NoMatch} />
        </Switch>
      </Router>
    </StateProvider>
  );
}

const MainContainer = styled.div`
  display: block;
  height: 100%;
  width: 100%;
`;

// const ClearCacheComponent = withClearCache(MainApp);

// function App() {
//   return <ClearCacheComponent />;
// }

export default App;
