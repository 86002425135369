import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';

const WrapperContainer = ({ children, intl }) => {
  const { locale } = intl;
  return (
    <Wrapper id="wrapper" locale={locale}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: block;
  margin: ${(props) => (props.locale === 'en-US' ? '25px 25px 25px 270px' : '25px 270px 25px 25px')};
  transition: margin-right 280ms ease, margin-left 280ms ease;
  @media (max-width: 1024px) {
    margin: 25px;
  }
`;

export default injectIntl(WrapperContainer);
