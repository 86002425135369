import axios from '../utils/axios';

export const fetchTransactions = (start = 0) => {
  return axios.get(`/transactions?_sort=createdAt:DESC&_limit=10&_start=${start}`);
};

export const fetchTransactionsCount = () => {
  return axios.get(`/transactions/count`);
};

export const deleteTransaction = (id) => {
  return axios.delete(`/transactions/${id}`);
};
