import React, { useState } from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// import bg from '../../assets/img/bg.svg';
import { FormInputField, Form, FormStrategy, Validators, Button, Notify } from 'zent';

import { login } from '../../services/authService';
import BG from '../../assets/img/bg.jpeg';

const Login = ({ history, intl }) => {
  const form = Form.useForm(FormStrategy.View);
  const [isLoading, setLoading] = useState(false);
  const { messages, locale } = intl;

  const submit = () => {
    setLoading(true);
    login(form.getValue())
      .then((res) => {
        localStorage.setItem('@token', res.data.jwt);
        localStorage.setItem('@userInfo', JSON.stringify(res.data.user));
        return history.push('/');
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 400) {
          Notify.error(messages['error.wrongUsernameOrPassword'], 4000);
        } else {
          Notify.error(messages['error.network'], 4000);
        }
      });
  };

  return (
    <BackgroundContainer className='animated fadeIn'>
      <Title locale={locale}>
        <FormattedMessage id='auth.title' />
      </Title>
      <FormContainer>
        <Form layout={'vertical'} form={form} onSubmit={submit} disableEnterSubmit={false}>
          <h1 className='form-title'>{messages['form.login']}</h1>
          <FormInputField
            name='identifier'
            props={{
              placeholder: messages['label.username'],
            }}
            validateOccasion={Form.ValidateOccasion.Blur | Form.ValidateOccasion.Change}
            validators={[Validators.required(messages['error.usernameRequired'])]}
            required='Required'
          />
          <FormInputField
            name='password'
            props={{
              type: 'password',
              placeholder: messages['label.password'],
            }}
            validateOccasion={Form.ValidateOccasion.Blur | Form.ValidateOccasion.Change}
            validators={[Validators.required(messages['error.passwordRequired'])]}
            required='Required'
          />
          <Button htmlType='submit' type='primary' loading={isLoading}>
            {messages['form.submit']}
          </Button>
        </Form>
        <Link className='forget-password' to='/forgetPassword'>
          {messages['label.forgetPassword']}
        </Link>
      </FormContainer>
    </BackgroundContainer>
  );
};

const BackgroundContainer = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-image: url(${BG});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
  }
`;

const Title = styled.h1`
  position: absolute;
  top: 20px;
  right: ${(props) => (props.locale === 'en-US' ? 'auto' : '20px')};
  left: ${(props) => (props.locale === 'en-US' ? '20px' : 'auto')};
  font-size: 2em;
  color: #fff;
  margin-bottom: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  z-index: 20;
`;

const FormContainer = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  padding: 35px;
  z-index: 20;
  width: 20vw;
  .zent-form-control {
    margin-bottom: 30px !important;
  }
  .zent-form-label {
    display: none;
  }
  .zent-input--size-normal {
    width: 100%;
    border-radius: 15px;
    border-color: #eee;
    height: 40px !important;
  }
  .zent-input {
    background-color: #fafafb;
    color: #333;
  }
  .zent-btn {
    border-radius: 6px;
    background-color: #013c64 !important;
    border-color: #013c64 !important;
    font-weight: bold;
    width: 100%;
  }
  .zent-btn span {
    /* !important */
    color: #fff;
  }
  .form-title {
    text-align: center;
    margin-bottom: 25px;
    font-size: 1.2em;
  }

  .forget-password {
    display: block;
    font-size: 14px;
    color: #013c64;
    text-align: center;
    margin-top: 20px;
  }
`;

export default injectIntl(Login);
