import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import { withBaseIcon } from 'react-icons-kit';
import { grid } from 'react-icons-kit/feather/grid';
import { users } from 'react-icons-kit/feather/users';
import { packageIcon } from 'react-icons-kit/feather/packageIcon';
import { folder } from 'react-icons-kit/feather/folder';
import { logOut } from 'react-icons-kit/feather/logOut';
import { creditCard } from 'react-icons-kit/feather/creditCard';
import { Sweetalert } from 'zent';
import { motion } from 'framer-motion';
import Scrollbar from 'react-scrollbars-custom';

import { useStateValue } from '../../context/state';
import { history } from '../../utils/history';

const WhiteIcon = withBaseIcon({ size: 25, style: { color: '#fff' } });
const BlueIcon = withBaseIcon({ size: 25, style: { color: '#013C64' } });

const Menu = ({ location, intl }) => {
  const [{ profile }] = useStateValue();
  const { messages, locale } = intl;
  const items = [
    {
      id: 0,
      name: messages['menu.item1'],
      route: '/',
      privacy: ['admin'],
    },
    {
      id: 1,
      name: messages['menu.item2'],
      route: '/products',
      privacy: ['admin'],
    },
    {
      id: 2,
      name: messages['menu.item3'],
      route: '/categories',
      privacy: ['admin'],
    },
    {
      id: 3,
      name: messages['menu.item4'],
      route: '/transactions',
      privacy: ['admin'],
    },
  ];

  const fetchMenuIcon = (location, path, index, link) => {
    let status = location === path;
    if (link.pathname && location.includes(link.pathname[0])) {
      status = true;
    }
    switch (index) {
      case 0:
        return status ? <BlueIcon icon={users} /> : <WhiteIcon icon={users} />;
      case 1:
        return status ? <BlueIcon icon={packageIcon} /> : <WhiteIcon icon={packageIcon} />;
      case 2:
        return status ? <BlueIcon icon={folder} /> : <WhiteIcon icon={folder} />;
      case 3:
        return status ? <BlueIcon icon={creditCard} /> : <WhiteIcon icon={creditCard} />;
      default:
        return null;
    }
  };

  const logOutAction = () => {
    Sweetalert.confirm({
      confirmType: 'success',
      confirmText: `${messages['alert.success']}`,
      cancelText: `${messages['alert.dismiss']}`,
      content: `${messages['alert.logOut']}`,
      title: `${messages['alert.title']}`,
      className: 'custom-sweetalert',
      maskClosable: true,
      parentComponent: this,
      onConfirm: () => {
        localStorage.removeItem('@token');
        history.push('/login');
      },
    });
  };

  const listItems = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const variants = {
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: i * 0.2,
        y: { stiffness: 1000, velocity: -100 },
      },
    }),
    hidden: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  const getActivePathName = (link) => {
    let className = '';
    if (link.pathname && location.pathname.includes(link.pathname[0])) {
      return (className = 'active');
    }
  };

  return (
    <Container locale={locale} id='menu'>
      <MenuContainer locale={locale}>
        <Title locale={locale}>
          <motion.div
            animate={{
              opacity: 1,
              transition: { delay: 1, y: { stiffness: 1000, velocity: -100 } },
            }}>
            <WhiteIcon icon={grid} /> <span className='text'>{messages['menu.title']}</span>
          </motion.div>
        </Title>
        <Scrollbar>
          {profile && (
            <div className='menu-router'>
              <motion.ul initial='hidden' animate='visible' variants={listItems}>
                {items.map((item, index) => {
                  let result = item.privacy.find((el) => el === profile.role.type);
                  if (result) {
                    return (
                      <motion.li custom={index} animate='visible' variants={variants} key={item.id} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                        <NavLink exact={item.route === '/'} className={getActivePathName(item)} activeClassName='active' to={`${item.route}`}>
                          {fetchMenuIcon(location.pathname, `${item.route}`, item.id, item)}
                          <span className='text'>{item.name}</span>
                        </NavLink>
                      </motion.li>
                    );
                  }
                  return null;
                })}
              </motion.ul>
            </div>
          )}
        </Scrollbar>
        <div className='bottom-bar'>
          <LogOutBtn locale={locale} onClick={logOutAction}>
            <BlueIcon icon={logOut} />
            <span className='text'>{messages['menu.logOutbtn']}</span>
          </LogOutBtn>
        </div>
      </MenuContainer>
    </Container>
  );
};

const Title = styled.h1`
  color: #fff;
  font-size: 1.4em;
  height: 55px;
  line-height: 55px;
  padding: 0 15px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  text-align: ${(props) => (props.locale === 'en-US' ? 'left' : 'right')};
  white-space: nowrap;
  @media (max-width: 1024px) {
    font-size: 1em;
    padding: 0 15px;
  }

  @media (max-width: 550px) {
    font-size: 1.3em;
  }
`;

const Container = styled.div`
  width: 250px;
  position: fixed;
  top: 0;
  right: ${(props) => (props.locale === 'en-US' ? 'auto' : 0)};
  left: ${(props) => (props.locale === 'en-US' ? 0 : 'auto')};
  bottom: 0;
  z-index: 10;
  background-color: #013c64;
  transition: opacity 280ms ease, visibility 280ms ease, transform 280ms ease, width 280ms ease;
  overflow: auto;
  &.is-active {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
    transition: opacity 280ms ease, visibility 280ms ease, transform 280ms ease;
  }
  &.is-open {
    width: 75px;
    transition: width 280ms ease;
  }
  @media (max-width: 1024px) {
    transform: translate(0, 0);
    width: 0;
    &.is-open {
      opacity: 1;
      visibility: visible;
      width: 150px;
      transform: translate(0, 0);
      transition: width 280ms ease;
    }
  }

  @media (max-width: 550px) {
    &.is-active {
      width: 80% !important;
    }
  }
`;

const MenuContainer = styled.ul`
  list-style: none;
  margin: 0;
  /* padding: 0 15px; */
  height: 100%;
  position: relative;
  overflow: hidden;
  &.is-open {
    text-align: center;
  }
  .bottom-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 15px;
  }
  .menu-router {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 60px;
    padding: 15px;
    display: block;
    height: calc(100% - 120px);
    overflow: auto;
  }
  li {
    display: block;
    margin-bottom: 25px;
    overflow: hidden;
    @media (max-width: 1024px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }
    @media (max-width: 550px) {
      justify-content: flex-start;
    }
    a {
      display: block;
      color: #fff;
      font-size: 16px;
      white-space: nowrap;
      padding: 10px;
      border-radius: 4px;
      background-color: transparent;
      transition: background-color 0.3s ease, color 0.3s ease;
      :hover {
        background-color: rgba(255, 255, 255, 0.5);
        color: #fff;
        transition: background-color 0.3s ease, color 0.3s ease;
        font-weight: bold;
      }
      i {
        margin-left: ${(props) => (props.locale === 'en-US' ? 0 : '10px')};
        margin-right: ${(props) => (props.locale === 'en-US' ? '10px' : 0)};
      }
      @media (max-width: 1024px) {
        & {
          font-size: 13px;
          padding: 5px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: ${(props) => (props.locale === 'en-US' ? 'flex-start' : 'flex-end')};
        }
        i {
          margin-left: 5px;
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
      @media (max-width: 550px) {
        font-size: 18px;
        width: auto;
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
    a.active {
      background-color: #fff;
      color: #013c64;
      font-weight: bold;
      transition: background-color 0.3s ease, color 0.3s ease;
    }
  }
`;

const LogOutBtn = styled.div`
  width: 45%;
  height: 30px;
  margin-right: ${(props) => (props.locale === 'en-US' ? '15px' : 0)};
  margin-left: ${(props) => (props.locale === 'en-US' ? 0 : '15px')};
  flex-direction: ${(props) => (props.locale === 'en-US' ? 'row' : 'row-reverse')};
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #013c64;
  font-weight: bold;
  border-radius: 4px;
  z-index: 10;
  cursor: pointer;
  :hover {
    box-shadow: 0px 5px 10px 0 rgb(0 0 0 / 20%);
  }
`;

export default withRouter(injectIntl(Menu));
