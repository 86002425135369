/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormInputField, FormSelectField, Form, FormStrategy, Validators, Button, Notify } from 'zent';
import { injectIntl } from 'react-intl';

import { addUser } from '../../services/userService';
import { fetchRoles } from '../../services/roleService';

const AddUser = ({ history, intl }) => {
  const form = Form.useForm(FormStrategy.View);
  const [isLoading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const { messages } = intl;

  useEffect(() => {
    fetchRoles()
      .then((res) => {
        res.data.roles.forEach((item) => {
          item['value'] = item.id;
          item['text'] = messages[`role.${item.type}`];
          return item;
        });
        setRoles(res.data.roles);
      })
      .catch((err) => console.log('error'));
  }, []);

  const submit = () => {
    setLoading(true);
    const { fullName, email, phoneNumber, username, password, role } = form.getValue();

    addUser({
      fullName,
      email,
      phoneNumber,
      username,
      password,
      role,
      confirmed: true,
    })
      .then((res) => {
        Notify.success(messages['success.addUser'], 4000);
        return history.replace('/users');
      })
      .catch((err) => {
        setLoading(true);
        Notify.error(messages['error.network'], 4000);
      });
  };

  return (
    <Container className='animated fadeIn'>
      <h1>{messages['user.add']}</h1>
      <Form layout={'vertical'} form={form} onSubmit={submit} disableEnterSubmit={false}>
        <div className='zent-form-row'>
          <FormInputField
            name='fullName'
            label={messages['label.fullName']}
            validateOccasion={Form.ValidateOccasion.Blur | Form.ValidateOccasion.Change}
            validators={[Validators.required(messages['error.isEmail'])]}
            required='Required'
          />
          <FormInputField
            name='email'
            label={messages['label.email']}
            validateOccasion={Form.ValidateOccasion.Blur | Form.ValidateOccasion.Change}
            validators={[Validators.required(messages['error.isEmail']), Validators.email(messages['error.emailRequired'])]}
            required='Required'
          />
          <FormInputField
            name='phoneNumber'
            label={messages['label.phoneNumber']}
            props={{
              type: 'tel',
            }}
            validateOccasion={Form.ValidateOccasion.Blur | Form.ValidateOccasion.Change}
            validators={[Validators.required(messages['error.MobileNameRequired']), Validators.pattern(/^([0-9\(\)\/\+ \-]*)$/)]}
            required='Required'
          />
        </div>
        <div className='zent-form-row'>
          <FormInputField
            name='username'
            label={messages['label.username']}
            validateOccasion={Form.ValidateOccasion.Blur | Form.ValidateOccasion.Change}
            validators={[Validators.required(messages['error.usernameRequired'])]}
            required='Required'
          />
          <FormInputField
            name='password'
            label={messages['label.password']}
            props={{
              minLength: 6,
              type: 'password',
            }}
            validateOccasion={Form.ValidateOccasion.Blur | Form.ValidateOccasion.Change}
            validators={[Validators.required(messages['error.passwordRequired']), Validators.minLength(messages['error.passwordMinimum'])]}
            // helpDesc="رمز عبور باید دارای ۶ کاراکتر باشد."
            required='Required'
          />
          <FormSelectField
            name='role'
            label={messages['label.role']}
            props={{
              placeholder: `${messages['placeholder.role']}`,
              data: roles,
              autoWidth: true,
            }}
            validateOccasion={Form.ValidateOccasion.Blur | Form.ValidateOccasion.Change}
            validators={[Validators.required(messages['error.roleRequired'])]}
            required='Required'
          />
        </div>
        <Button htmlType='submit' type='primary' loading={isLoading}>
          {messages['label.submit']}
        </Button>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
  padding: 35px 35px 35px 20px;
  border-radius: 6px;
  h1 {
    font-size: 2em;
    font-weight: bold;
    color: #222;
    margin-bottom: 30px;
  }
`;

export default injectIntl(AddUser);
