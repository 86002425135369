import React, { useState, useEffect } from 'react';
import { Grid, Notify, Button, Sweetalert, Tag } from 'zent';
import moment from 'jalali-moment';
import { injectIntl } from 'react-intl';
import { withBaseIcon } from 'react-icons-kit';
import { trash2 } from 'react-icons-kit/feather/trash2';

import { fetchTransactions, fetchTransactionsCount, deleteTransaction } from '../services/transactionService';
import Block from '../components/common/block';

const Icon = withBaseIcon({ size: 20, style: { color: '#555' } });

const Transactions = ({ intl, history }) => {
  const [datasets, setDatasets] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState({
    pageSize: 10,
    total: 0,
    current: 0,
    start: 0,
  });
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [searchText, setSearchText] = useState('');
  const { messages, locale } = intl;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (page = 0, start = 0) => {
    Promise.all([fetchTransactions(start), fetchTransactionsCount()])
      .then((res) => {
        setDatasets(res[0].data);
        setPageInfo({ ...pageInfo, total: res[1].data, current: page });
        setLoading(false);
      })
      .catch((err) => Notify.error(messages['error.network']));
  };

  const onChange = ({ current }) => {
    setLoading(false);
    fetchData(Number(current), (current - 2) * 10 + 10);
  };

  const removeTransaction = (id) => {
    Sweetalert.confirm({
      content: `${messages['alert.removeText']}`,
      title: `${messages['alert.title']}`,
      confirmType: 'danger',
      confirmText: `${messages['alert.success']}`,
      cancelText: `${messages['alert.dismiss']}`,
      onConfirm: () =>
        new Promise((resolve) => {
          deleteTransaction(id)
            .then(() => {
              fetchData(0, 0);
              Notify.success(messages['user.removeSuccess'], 5000);
              return resolve();
            })
            .catch((err) => {
              Notify.error(messages['error.network']);
              return resolve();
            });
        }),
    });
  };

  const renderExpireDate = (data) => {
    if (data.package) {
      switch (data.package) {
        case 1:
          return moment(data.createdAt).add(1, 'jYear').format('jYYYY/jMM/jDD');
        case 2:
          return moment(data.createdAt).add(6, 'jMonth').format('jYYYY/jMM/jDD');
        default:
          return moment(data.createdAt).add(1, 'jYear').format('jYYYY/jMM/jDD');
      }
    }
    return moment(data.createdAt).add(1, 'jYear').format('jYYYY/jMM/jDD');
  };

  const columns = [
    {
      title: messages['label.username'],
      bodyRender: (data) => data.user?.username,
    },
    { title: messages['label.status'], bodyRender: (data) => (data.status === 100 ? <Tag theme='green'>پرداخت شده</Tag> : <Tag theme='red'>پرداخت نشده</Tag>) },
    {
      title: messages['label.createdAt'],
      bodyRender: (data) => {
        return moment(data.createdAt)
          .locale(locale === 'en-US' ? 'en' : 'fa')
          .format('YYYY/M/D - HH:mm');
      },
    },
    {
      title: messages['label.expireDate'],
      bodyRender: (data) => {
        return renderExpireDate(data);
      },
    },
    {
      title: '',
      bodyRender: (data) => {
        return (
          <div className='table-control__container'>
            <Button type='danger' className='icon' onClick={() => removeTransaction(data.id)}>
              <Icon style={{ marginLeft: 5 }} icon={trash2} />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className='animated fadeIn'>
      <Block>
        <h1 className='title'>{messages['transaction.title']}</h1>
      </Block>
      <Grid pageInfo={pageInfo} columns={columns} datasets={datasets} onChange={onChange} emptyLabel={`${messages['transaction.empty']}`} loading={isLoading} />
    </div>
  );
};

export default injectIntl(Transactions);
