import axios from '../utils/axios';

export const fetchCategories = (query = '') => {
  let url = query !== '' ? `/categories?_q=${query}&_sort=createdAt:DESC` : `/categories?_sort=createdAt:DESC`;
  return axios.get(url);
};

export const fetchCategoriesCount = () => {
  return axios.get('/categories/count');
};

export const addCategory = (data) => {
  return axios.post('/categories', data);
};

export const deleteCategory = (id) => {
  return axios.delete(`/categories/${id}`);
};

export const editCategory = (data, id) => {
  return axios.put(`/categories/${id}`, data);
};
