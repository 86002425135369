import axios from '../utils/axios';

export const fetchProfile = () => {
  return axios.get('/users/me');
};

export const fetchUsers = (query = '', start = 0, selectedRole = null) => {
  let url = query !== '' ? `/users?_q=${query}&_sort=createdAt:DESC&_limit=10&_start=${start}` : `/users?_sort=createdAt:DESC&_limit=10&_start=${start}`;

  if (selectedRole) {
    url += `&role=${selectedRole}`;
  }
  return axios.get(url);
};

export const fetchCustomers = (query = '', start = 0) => {
  let url = query !== '' ? `/users?type=2&_q=${query}&_sort=createdAt:DESC&_limit=10&_start=${start}` : `/users?type=2&_sort=createdAt:DESC&_limit=10&_start=${start}`;
  return axios.get(url);
};

export const fetchCustomersCount = () => {
  return axios.get('/users/count?type_eq=2');
};

export const fetchAllUsers = () => {
  return axios.get(`/users?_sort=createdAt:DESC`);
};

export const fetchAllCustomers = (query = '', page, start) => {
  return axios.get(`/users?type=2&_sort=createdAt:DESC`);
};

export const fetchUsersCount = () => {
  return axios.get('/users/count');
};

export const fetchSingleUsers = (id) => {
  return axios.get(`/users/${id}`);
};

export const addUser = (data) => {
  return axios.post('/users', data);
};

export const deleteUser = (id) => {
  return axios.delete(`/users/${id}`);
};

export const editUser = (data, id) => {
  return axios.put(`/users/${id}`, data);
};

export const activeUserPayment = (id, isPaid) => {
  return axios.put(`/users/${id}`, { isPaid });
};
