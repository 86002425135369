/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormInputField, FormSelectField, Form, FormStrategy, Validators, Button, Notify, BlockLoading } from 'zent';
import { injectIntl } from 'react-intl';

import { fetchSingleUsers, editUser } from '../../services/userService';
import { fetchRoles } from '../../services/roleService';

const EditUser = ({ history, match, intl }) => {
  const form = Form.useForm(FormStrategy.View);
  const [isLoading, setLoading] = useState(false);
  const [isContentLoading, setContentLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const { messages } = intl;

  useEffect(() => {
    Promise.all([fetchSingleUsers(match.params.id), fetchRoles()]).then((res) => {
      const { fullName, email, phoneNumber, blocked, role, username } = res[0].data;
      form.patchValue({
        username,
        fullName,
        email,
        phoneNumber,
        role: role.id,
        blocked,
      });
      let result = res[1].data.roles.map((item) => {
        item['value'] = item.id;
        item['text'] = messages[`role.${item.type}`];
        return item;
      });
      setRoles(result);
      setContentLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  const submit = () => {
    setLoading(true);
    const { fullName, email, phoneNumber, blocked, role } = form.getValue();
    editUser({ fullName, email, phoneNumber, blocked, role }, match.params.id)
      .then((res) => {
        Notify.success(4000, messages['success.updateUser']);
        return history.replace('/users');
      })
      .catch((err) => Notify.error(messages['error.network'], 4000));
  };

  return (
    <Container className='animated fadeIn'>
      <BlockLoading loading={isContentLoading}>
        <h1>{messages['user.edit']}</h1>
        <Form layout={'vertical'} form={form} onSubmit={submit} disableEnterSubmit={false}>
          <div className='zent-form-row'>
            <FormInputField
              name='fullName'
              label={messages['label.fullName']}
              validateOccasion={Form.ValidateOccasion.Blur | Form.ValidateOccasion.Change}
              validators={[Validators.required(messages['error.isEmail'])]}
              required='Required'
            />
            <FormInputField
              name='email'
              label={messages['label.email']}
              validateOccasion={Form.ValidateOccasion.Blur | Form.ValidateOccasion.Change}
              validators={[Validators.required(messages['error.isEmail']), Validators.email(messages['error.emailRequired'])]}
              required='Required'
            />
            <FormInputField
              name='phoneNumber'
              label={messages['label.phoneNumber']}
              props={{
                type: 'tel',
              }}
              validateOccasion={Form.ValidateOccasion.Blur | Form.ValidateOccasion.Change}
              validators={[Validators.required(messages['error.MobileNameRequired']), Validators.pattern(/^([0-9\(\)\/\+ \-]*)$/)]}
              required='Required'
            />
          </div>
          <div className='zent-form-row'>
            <FormInputField
              name='username'
              label={messages['label.username']}
              validateOccasion={Form.ValidateOccasion.Blur | Form.ValidateOccasion.Change}
              validators={[Validators.required(messages['error.usernameRequired'])]}
              required='Required'
            />
            <FormSelectField
              name='role'
              label={messages['label.role']}
              props={{
                placeholder: `${messages['placeholder.role']}`,
                data: roles,
                autoWidth: true,
              }}
              validateOccasion={Form.ValidateOccasion.Blur | Form.ValidateOccasion.Change}
              validators={[Validators.required(messages['error.roleRequired'])]}
              required='Required'
            />
          </div>
          <Button htmlType='submit' type='primary' loading={isLoading}>
            {messages['label.update']}
          </Button>
        </Form>
      </BlockLoading>
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
  padding: 35px 35px 35px 20px;
  border-radius: 6px;
  h1 {
    font-size: 2em;
    font-weight: bold;
    color: #222;
    margin-bottom: 30px;
  }
`;

export default injectIntl(EditUser);
