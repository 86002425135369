import axios from '../utils/axios';

export const fetchProducts = (query = '', start = 0, range = null) => {
  let url = query !== '' ? `/products?_q=${query}&_sort=createdAt:DESC&_limit=10&_start=${start}` : `/products?_sort=createdAt:DESC&_limit=10&_start=${start}`;

  if (range) {
    url += `&count_lte=${range}`;
  }

  return axios.get(url);
};

export const fetchProductsCount = (query = '', start = 0, range = null) => {
  let url = query !== '' ? `/products/count?_q=${query}&_sort=createdAt:DESC&_limit=10&_start=${start}` : `/products/count?_sort=createdAt:DESC&_limit=10&_start=${start}`;

  if (range) {
    url += `&count_lte=${range}`;
  }

  return axios.get(url);
};

export const fetchProductsByUserId = (query = '', start = 0, id = null) => {
  let url = query !== '' ? `/products?_q=${query}&_sort=createdAt:DESC&_limit=10&_start=${start}` : `/products?_sort=createdAt:DESC&_limit=10&_start=${start}`;

  if (id) {
    url += `&user._id=${id}`;
  }

  return axios.get(url);
};

export const fetchProductsCountByUserId = (query = '', start = 0, id = null) => {
  let url = query !== '' ? `/products/count?_q=${query}&_sort=createdAt:DESC&_limit=10&_start=${start}` : `/products/count?_sort=createdAt:DESC&_limit=10&_start=${start}`;

  if (id) {
    url += `&user._id=${id}`;
  }

  return axios.get(url);
};

export const fetchAllProducts = () => {
  return axios.get('/products?_sort=createdAt:DESC&_limit=300');
};

export const fetchSingleProduct = (id) => {
  return axios.get(`/products/${id}`);
};

export const addProduct = (data) => {
  return axios.post('/products', data);
};

export const editProduct = (data, id) => {
  return axios.put(`/products/${id}`, data);
};

export const deleteProduct = (id) => {
  return axios.delete(`/products/${id}`);
};

export const fetchBrands = () => {
  return axios.get(`/brands`);
};

export const editProductVariant = (count, parentId, id) => {
  return axios.put(
    process.env.NODE_ENV === 'production' ? `https://185.88.154.250:3200/v1/products/variant/${parentId}/${id}` : `https://localhost:3200/v1/products/variant/${parentId}/${id}`,
    count
  );
};
