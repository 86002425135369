import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormInputField, FormSelectField, Form, FormStrategy, Validators, Button, Notify, ImageUpload, BlockLoading, previewImage, Icon, Input, Tag } from 'zent';
import { injectIntl } from 'react-intl';

// Services
import { addProduct } from '../../services/productService';
import { fetchCategories } from '../../services/categoryService';
import axios from '../../utils/axios';

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const AddProduct = ({ history, intl }) => {
  const form = Form.useForm(FormStrategy.View);
  const [isLoading, setLoading] = useState(false);
  const [isContentLoading, setContentLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [content, setContent] = useState('');
  const [images, setImage] = useState([]);
  const { messages } = intl;

  useEffect(() => {
    form.patchValue({
      count: 0,
    });
    fetchCategories()
      .then((res) => {
        let result = [];
        res.data.forEach((item) => {
          return result.push({ value: item.id, text: item.title });
        });
        setCategories(result);
        setContentLoading(false);
      })
      .catch((err) => setContentLoading(false));
  }, []);

  const onUploadChange = (files, detail) => {
    if (detail.type === 'delete') {
      return setImage(files);
    }
  };

  const onUpload = (file, report) => {
    setContentLoading(true);
    let formData = new FormData();
    formData.append('files', file, file.name);
    return axios
      .post('/upload/', formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      .then((res) => {
        images.push(res.data[0]);
        setImage(images);
        setContentLoading(false);
      });
  };

  const onUploadError = (type, data) => {
    if (type === 'overMaxAmount') {
      Notify.error(`حداکثر تعداد آپلود فایل ${data.maxAmount} است.`);
    } else if (type === 'overMaxSize') {
      Notify.error(`حداکثر حجم فایل ${data.formattedMaxSize} است.`);
    }
  };

  const handlePreview = (e) => {
    let imgArr = images.map((item) => {
      return process.env.NODE_ENV === 'production' ? `https://api.medrugapp.ir${item.url}` : `http://localhost:3126${item.url}`;
    });
    previewImage({
      images: imgArr,
      index: imgArr.indexOf(e.target.src),
      parentComponent: this,
      showRotateBtn: false,
      scaleRatio: 3,
    });
  };

  const removeImage = (e, id) => {
    e.stopPropagation();
    let result = images.filter((item) => item.id !== id);
    setImage(result);
  };

  const addRow = () => {
    let array = items;
    array.push({ id: uuidv4(), name: content });
    setContent('');
    return setItems([...array]);
  };

  const removeRow = (id) => {
    let array = items;
    let result = array.filter((item) => item.id !== id);
    return setItems([...result]);
  };

  const submit = () => {
    setLoading(true);
    const { title, categories, description, tips, ingredient } = form.getValue();
    addProduct({
      title,
      categories,
      images,
      description,
      tips,
      items,
      ingredient,
    })
      .then((res) => {
        Notify.success(messages['success.addProduct'], 4000);
        return history.replace('/products');
      })
      .catch((err) => Notify.error(messages['error.network'], 4000));
  };

  return (
    <Container className='animated fadeIn'>
      <BlockLoading loading={isContentLoading}>
        <h1>{messages['product.add']}</h1>
        <Form layout={'vertical'} form={form} onSubmit={submit} disableEnterSubmit={false}>
          <div className='zent-form-row'>
            <FormInputField
              name='title'
              label={messages['label.name']}
              validateOccasion={Form.ValidateOccasion.Blur | Form.ValidateOccasion.Change}
              validators={[Validators.required(messages['error.error.nameRequired'])]}
              required='Required'
            />
            <FormSelectField
              name='categories'
              label={messages['label.category']}
              props={{
                placeholder: `${messages['placeholder.category']}`,
                data: categories,
                autoWidth: true,
              }}
              validateOccasion={Form.ValidateOccasion.Blur | Form.ValidateOccasion.Change}
              validators={[Validators.required(messages['error.categoryRequired'])]}
              required='Required'
            />
            <FormInputField name='ingredient' label={messages['label.ingredient']} />
          </div>
          <div className='zent-form-row'>
            <FormInputField name='description' label={messages['label.description']} />
            <FormInputField name='tips' label={messages['label.tips']} />
          </div>
          <div className='zent-form-row'>
            <Input style={{ marginLeft: '20px' }} onChange={(e) => setContent(e.target.value)} placeholder={messages['placeholder.item']} value={content} />
            <Button type='primary' onClick={addRow}>
              {messages['label.add']}
            </Button>
          </div>
          <div className='zent-form-row' style={{ marginTop: '15px', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
            {items.map((item) => {
              return (
                <Tag
                  style={{ borderColor: '#87d068', backgroundColor: '#cfefdf', color: '#00a854', marginLeft: '10px', marginBottom: '10px' }}
                  key={item.id}
                  closable
                  onClose={() => removeRow(item.id)}>
                  {item.name}
                </Tag>
              );
            })}
          </div>
          <div className='product-slider'>
            {images.map((item) => {
              return (
                <div className='items' key={item.id} onClick={handlePreview}>
                  <span className='remove' onClick={(e) => removeImage(e, item.id)}>
                    <Icon type='close' />
                  </span>
                  <img src={process.env.NODE_ENV === 'production' ? `https://api.medrugapp.ir${item.url}` : `http://localhost:3126${item.url}`} alt={item.name} />
                </div>
              );
            })}
          </div>
          <div className='zent-form-row'>
            <div className='zent-form-control'>
              <label className='zent-form-label'>{messages['label.image']}</label>
              <ImageUpload
                className='zent-image-upload-demo'
                maxSize={2 * 1024 * 1024}
                maxAmount={9}
                multiple
                onChange={onUploadChange}
                onUpload={onUpload}
                onError={onUploadError}
              />
            </div>
            <div className='zent-form-control'></div>
            <div className='zent-form-control'></div>
          </div>
          <Button htmlType='submit' type='primary' loading={isLoading}>
            {messages['label.submit']}
          </Button>
        </Form>
      </BlockLoading>
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
  padding: 35px 35px 35px 20px;
  border-radius: 6px;
  h1 {
    font-size: 2em;
    font-weight: bold;
    color: #222;
    margin-bottom: 30px;
  }
`;

export default injectIntl(AddProduct);
