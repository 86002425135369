import React, { useState, useEffect } from 'react';
import { Grid, Notify, Button, Input, Sweetalert, Select, Switch } from 'zent';
import moment from 'jalali-moment';
import { injectIntl } from 'react-intl';
import { withBaseIcon } from 'react-icons-kit';
import { edit } from 'react-icons-kit/feather/edit';
import { trash2 } from 'react-icons-kit/feather/trash2';

import { fetchUsers, fetchUsersCount, deleteUser, activeUserPayment } from '../../services/userService';
import { fetchRoles } from '../../services/roleService';
import Block from '../../components/common/block';

const Icon = withBaseIcon({ size: 20, style: { color: '#555' } });

const Users = ({ intl, history }) => {
  const [datasets, setDatasets] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState({
    pageSize: 10,
    total: 0,
    current: 0,
    start: 0,
  });
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [searchText, setSearchText] = useState('');
  const { messages, locale } = intl;

  useEffect(() => {
    fetchData();
    fetchRoles().then((res) => {
      res.data.roles.forEach((item) => {
        item['value'] = item.id;
        item['text'] = messages[`role.${item.type}`];
        return item;
      });
      let result = res.data.roles;
      result.push({ id: null, text: messages['label.all'] });
      setRoles(res.data.roles);
    });
  }, []);

  const fetchData = (query = '', page = 0, start = 0, selectedRole = null) => {
    Promise.all([fetchUsers(query, start, selectedRole), fetchUsersCount(query, start)])
      .then((res) => {
        setDatasets(res[0].data);
        setPageInfo({ ...pageInfo, total: res[1].data, current: page });
        setLoading(false);
      })
      .catch((err) => Notify.error(messages['error.network']));
  };

  const onChange = ({ current }) => {
    setLoading(false);
    fetchData('', Number(current), (current - 2) * 10 + 10, selectedRole);
  };

  const removeUser = (id) => {
    Sweetalert.confirm({
      content: `${messages['alert.removeText']}`,
      title: `${messages['alert.title']}`,
      confirmType: 'danger',
      confirmText: `${messages['alert.success']}`,
      cancelText: `${messages['alert.dismiss']}`,
      onConfirm: () =>
        new Promise((resolve) => {
          deleteUser(id)
            .then(() => {
              fetchData(searchText, pageInfo.current, (pageInfo.current - 2) * 10 + 10, selectedRole);
              Notify.success(messages['user.removeSuccess'], 5000);
              return resolve();
            })
            .catch((err) => {
              Notify.error(messages['error.network']);
              return resolve();
            });
        }),
    });
  };

  const onChangeSearch = (e) => {
    let value = e.target.value;
    if (value && value.trim() !== '') {
      setLoading(true);
      return fetchData(value, 0, 0, selectedRole);
    } else if (value.trim() === '') {
      setLoading(true);
      return fetchData('', 0, 0, selectedRole);
    }
  };

  const onPressEnter = (e) => {
    if (e.target.value && e.target.value.trim() !== '') {
      setSearchText(e.target.value);
      setLoading(true);
      return fetchData(e.target.value, 0, 0, selectedRole);
    }
  };

  const onChangeRole = (e, data) => {
    setLoading(true);
    setSelectedRole(data);
    return fetchData(searchText, 0, 0, data.id);
  };

  const changePaidStatus = (user) => {
    let result = datasets.find((item) => item.id === user.id);
    if (result) {
      result.isPaid = !user.isPaid;
      activeUserPayment(user.id, result.isPaid);
      return setDatasets([...datasets, result]);
    }
    return;
  };

  const columns = [
    {
      title: messages['label.username'],
      name: 'username',
    },
    {
      title: messages['label.email'],
      name: 'email',
    },
    {
      title: messages['label.phoneNumber'],
      name: 'phoneNumber',
    },
    {
      title: messages['label.status'],
      bodyRender: (data) => <Switch checked={data.isPaid} onChange={() => changePaidStatus(data)} />,
    },
    {
      title: messages['label.createdAt'],
      name: 'createdAt',
      bodyRender: (data) => {
        return moment(data.createdAt)
          .locale(locale === 'en-US' ? 'en' : 'fa')
          .format('YYYY/M/D - HH:mm');
      },
    },
    {
      title: messages['label.role'],
      bodyRender: (data) => {
        return messages[`role.${data.role.type}`];
      },
    },
    {
      title: '',
      bodyRender: (data) => {
        return (
          <div className='table-control__container'>
            <Button type='primary' className='icon' onClick={() => history.push(`/user/${data.id}`)}>
              <Icon style={{ marginLeft: 5 }} icon={edit} />
            </Button>
            <Button type='danger' className='icon' onClick={() => removeUser(data.id)}>
              <Icon style={{ marginLeft: 5 }} icon={trash2} />
            </Button>
          </div>
        );
      },
    },
  ];

  console.log(roles);
  return (
    <div className='animated fadeIn'>
      <Block>
        <h1 className='title'>{messages['user.title']}</h1>
        <div className='row'>
          <Input onPressEnter={onPressEnter} onChange={onChangeSearch} icon='search' placeholder={`${messages['search.placeholder']}`} />
          <Select placeholder={`${messages['placeholder.role']}`} data={roles} onChange={onChangeRole} value={selectedRole} />
          <Button className='add-btn' onClick={() => history.push('/user/add')}>
            {messages['user.add']}
          </Button>
        </div>
      </Block>
      <Grid pageInfo={pageInfo} columns={columns} datasets={datasets} onChange={onChange} emptyLabel={`${messages['user.empty']}`} loading={isLoading} />
    </div>
  );
};

export default injectIntl(Users);
