import React, { useState, useEffect, memo } from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { Portal, Input, Notify, Button } from 'zent';
import { withBaseIcon } from 'react-icons-kit';
import { mail } from 'react-icons-kit/feather/mail';

// Services
import { addCategory, editCategory } from '../../services/categoryService';

const WhiteIcon = withBaseIcon({ size: 25, style: { color: '#fff' } });

const TooltipContainer = memo(({ intl, modal, onClose, onUpdate }) => {
  const [title, setTitle] = useState('');
  const [isLoading, setLoading] = useState(false);
  const { locale, messages } = intl;

  useEffect(() => {
    if (modal.selectedItem) {
      return setTitle(modal.selectedItem.title);
    }
  }, [modal]);

  const submit = () => {
    if (title !== '') {
      setLoading(true);
      if (modal.selectedItem) {
        return editCategory({ title }, modal.selectedItem.id).then(() => {
          setLoading(false);
          onUpdate();
          return Notify.success(messages['success.updateCategory'], 5000);
        });
      }
      return addCategory({ title }).then(() => {
        setLoading(false);
        onUpdate();
        return Notify.success(messages['success.addCategory'], 5000);
      });
    } else {
      Notify.error(messages['error.titleRequired'], 5000);
    }
  };

  return (
    <>
      <Container locale={locale}>
        <IconContainer onClick={onClose}>
          <WhiteIcon icon={mail} />
        </IconContainer>
      </Container>
      <Portal visible={modal} onClose={onClose} className='layer' style={{ background: 'rgba(0, 0, 0, 0.4)' }} useLayerForClickAway closeOnClickOutside closeOnESC blockPageScroll>
        <div className='category-modal'>
          <div className='row'>
            <label>{messages['label.title']}</label>
            <Input type='text' onChange={(e) => setTitle(e.target.value)} value={title} />
          </div>
          <div className='row'>
            <Button type='primary' onClick={submit} loading={isLoading}>
              {modal.selectedItem ? messages['label.update'] : messages['label.submit']}
            </Button>
          </div>
        </div>
      </Portal>
    </>
  );
});

const Container = styled.div`
  position: fixed;
  z-index: 200;
  bottom: 10px;
  right: ${(props) => (props.locale === 'en-US' ? '10px' : 'auto')};
  left: ${(props) => (props.locale === 'en-US' ? 'auto' : '10px')};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #013c64;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 35%);
  cursor: pointer;
`;

export default injectIntl(TooltipContainer);
