import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withBaseIcon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/feather/menu';
import { chevronLeft } from 'react-icons-kit/feather/chevronLeft';
import { user } from 'react-icons-kit/feather/user';
import OutsideClickHandler from 'react-outside-click-handler';
import { injectIntl } from 'react-intl';
import { withRouter, useLocation } from 'react-router-dom';
// import LogRocket from "logrocket";

import { useStateValue } from '../../context/state';
import { fetchProfile } from '../../services/userService';

const Icon = withBaseIcon({ size: 25, style: { color: '#013C64' } });
const UserIcon = withBaseIcon({ size: 22, style: { color: '#999' } });

const windowWidth = window.innerWidth;

const Navbar = ({ intl, history }) => {
  const [result, setResult] = useState(null);
  const [showBackButton, setShowBackButton] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [{ profile }, dispatch] = useStateValue();
  const { locale } = intl;
  let location = useLocation();

  const fetchProfileApi = () => {
    return fetchProfile()
      .then((res) => {
        setResult(res.data);
        dispatch({
          type: 'updateProfile',
          profile: res.data,
        });
      })
      .catch((err) => console.log(err.response));
  };

  useEffect(() => {
    fetchProfileApi();
  }, []);

  useEffect(() => {
    let url = window.location.href.split('/');
    url.length > 4 ? setShowBackButton(true) : setShowBackButton(false);
  }, [location]);

  const renderName = () => {
    if (result.fullName) {
      return result.fullName;
    }

    if (result.username) {
      return result.username;
    }

    if (result.email) {
      return result.email;
    }
  };

  const toggleMenu = () => {
    let status = document.getElementById('menu').classList;
    if (windowWidth < 768) {
      if (status.contains('is-active')) {
        document.getElementById('menu').classList.remove('is-active');
        return document.getElementById('navbar').classList.remove('is-active');
      }
      document.getElementById('menu').classList.add('is-active');
      return document.getElementById('navbar').classList.add('is-active');
    } else {
      if (status.contains('is-open')) {
        document.getElementById('menu').classList.remove('is-open');
        document.getElementById('wrapper').classList.remove('is-open');
        return document.getElementById('navbar').classList.remove('is-open');
      }
      document.getElementById('menu').classList.add('is-open');
      document.getElementById('wrapper').classList.add('is-open');
      return document.getElementById('navbar').classList.add('is-open');
    }
  };

  const closeMenu = () => {
    return document.getElementById('navbar').classList.remove('is-active');
  };

  return (
    <OutsideClickHandler onOutsideClick={closeMenu}>
      <Container status={showBackButton} locale={locale} id='navbar'>
        <span className='name'>
          {showBackButton && (
            <div className='back-btn' onClick={() => history.goBack()}>
              <Icon icon={chevronLeft}></Icon>
            </div>
          )}
          <div className='menu-btn' onClick={toggleMenu}>
            <Icon icon={menu}></Icon>
          </div>
        </span>
        <div className='logout-btn'>
          <div className='profile-picture__container'>
            <UserIcon icon={user} />
          </div>
          {result && renderName()}
        </div>
      </Container>
    </OutsideClickHandler>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #eee;
	padding: 10px 25px;
	padding-right: ${(props) => (props.locale === 'en-US' ? '60px' : '25px')};
	padding-left: ${(props) => (props.locale === 'en-US' ? '25px' : '60px')}
  height: 55px;
  margin-right: ${(props) => (props.locale === 'en-US' ? 0 : '250px')};
  margin-left: ${(props) => (props.locale === 'en-US' ? '250px' : 0)};
  transition: all 280ms ease;
  &.is-active {
    margin-right: ${(props) => (props.locale === 'en-US' ? 0 : '250px')};
    margin-left: ${(props) => (props.locale === 'en-US' ? '250px' : 0)};
    transition: all 280ms ease;
  }
  &.is-open {
    margin-right: ${(props) => (props.locale === 'en-US' ? 0 : '75px')};
    margin-left: ${(props) => (props.locale === 'en-US' ? '75px' : 0)};
    transition: all 280ms ease;
  }
  @media (max-width: 1024px) {
    margin-right: 0;
    margin-left: 0;
    &.is-open {
      margin-right: ${(props) => (props.locale === 'en-US' ? 0 : '150px')};
      margin-left: ${(props) => (props.locale === 'en-US' ? '150px' : 0)};
    }
  }
  @media (max-width: 550px) {
    &.is-active {
      margin-right: 0;
      margin-left: 0;
    }
  }
  .logout-btn {
    display: inline-flex;
    align-items: center;
    flex-direction: ${(props) => (props.locale === 'en-US' ? 'row' : 'row-reverse')};
  }
  .name {
    color: #222;
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    i {
      vertical-align: bottom;
      margin-left: ${(props) => (props.locale === 'en-US' ? 0 : '5px')};
      margin-right: ${(props) => (props.locale === 'en-US' ? '5px' : 0)};
    }
    .menu-btn {
      margin-left: ${(props) => (props.locale === 'en-US' ? '60px' : 0)};
      margin-right: ${(props) => (props.locale === 'en-US' ? '60px' : 0)};
      cursor: pointer;
    }

    .back-btn {
      position: absolute;
      left: ${(props) => (props.locale === 'en-US' ? 0 : 'auto')};
      right: ${(props) => (props.locale === 'en-US' ? 0 : 'auto')};
      top: 0;
      bottom: 0;
      padding: 0 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f5f5f5;
    }
  }
  .profile-picture__container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #eee;
    margin: 0 5px;
  }
`;

export default withRouter(injectIntl(Navbar));
